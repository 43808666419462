$font__primary: 'Poppins', sans-serif;
$font__heading: 'Poppins', sans-serif;

$color__theme: #fa7070;
$color__theme-2: #7052fb;
$color__theme-3: #fbab41;
$color__primary: #2b2350;
$color__body: #797687;
$background__bg: #fdf8f8;
$background__2: #f8f7fc;

$gradient_background: linear-gradient(100deg, rgb(250, 112, 112) 0%, rgb(250, 151, 112) 100%);
$gradient_background_2: linear-gradient(-60deg, rgb(112, 82, 251) 0%, rgb(138, 96, 253) 100%);